<template>
  <modal
    name="modal-accept--agreement"
    class="modal-accept--agreement"
    width="680"
    :adaptive="true"
    :scrollable="true"
    :reset="true"
    height="auto"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.customAgreement.modalTitleAccept') }}
      </div>
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">
          {{ $t('booking.modal.customAgreement.modalTitleAccept') }}
        </div>
        <div class="alert alert-warning mt-2" role="alert">
          *{{ $t('booking.modal.customAgreement.modalDescriptionAccept') }}
        </div>
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <button class="btn btn-primary--outline" :class="{ 'mr-4': $mq !== 'xs' }" @click="close">
            {{ $t('booking.modal.customAgreement.back') }}
          </button>
          <button
            class="btn btn-primary"
            :class="{ 'ml-4': $mq !== 'xs' }"
            @click="acceptAgreement"
          >
            {{ $t('booking.modal.customAgreement.ACCEPT') }}
          </button>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      customAgreementData: state => state.v2.booking.detail.customAgreementData,
    }),
  },
  methods: {
    close() {
      this.$modal.hide('modal-accept--agreement');
    },
    async acceptAgreement() {
      let response = await this.$store.dispatch(
        'v2/booking/detail/acceptCustomAgreement',
        this.customAgreementData.uuid,
      );
      if (response.type === 'success') {
        this.$store.dispatch('v2/booking/detail/getData');
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  //font-size: $font-xl;
}
</style>
